import * as React from "react"
import {graphql} from "gatsby";
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import parse from "html-react-parser";
import defaultFrontCover from "../../images/coming-soon-front-cover.png"
import "./humane-heroes-volume.scss"

export const query = graphql`
    query($id: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "humaneHeroesBookPage", id: $id) {
          ... on Craft_humaneHeroesBookPage_default_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            uri
            title
            bookSubtitle
            contentDefaultHtmlEducation1
            contentDefaultHtmlEducation2
            educationBookFrontCover {
              url(transform: "bookCoverLarge")
              ... on Craft_websiteEducationGeneral_Asset {
                altText
              }
            }
          }
        }
      }
    }
  `

const HumaneHeroesVolumePage = ({ data, pageContext }) => {

    const getBookFrontCover = (node) => {
        if (node.educationBookFrontCover.length > 0) {
            return node.educationBookFrontCover[0].url
        } else {
            return defaultFrontCover
        }
    }

    const getBookFrontCoverAltText = (node) => {
        if (node.educationBookFrontCover.length > 0) {
            return node.educationBookFrontCover[0].altText
        } else {
            return null
        }
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd humane-heroes-volume-page">

                <PageTitle title={data.craft.page.title} className="education" />

                <div className="container-small">
                    <div className="content-colum-row heroes-volume-row padding-top-none">
                        <div className="sidebar">
                            <div className="heroes-volume-left">
                                <img src={getBookFrontCover(data.craft.page)} alt={getBookFrontCoverAltText(data.craft.page)} />
                            </div>
                        </div>
                        <div className="content-right">
                            <div className="inspirationRow-stories-blog">
                                <div className="breadcrumb">
                                    <ul>
                                        <li><a href="/ah">Humane Heroes</a></li>
                                        <li>{data.craft.page.title}</li>
                                    </ul>
                                </div>
                                <h1 className="title">{data.craft.page.title}</h1>
                                <div className="subtitle">{data.craft.page.bookSubtitle && parse(data.craft.page.bookSubtitle)}</div>
                                <div className="content">{data.craft.page.contentDefaultHtmlEducation1 && parse(data.craft.page.contentDefaultHtmlEducation1)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="additional-content">{data.craft.page.contentDefaultHtmlEducation2 && parse(data.craft.page.contentDefaultHtmlEducation2)}</div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default HumaneHeroesVolumePage
